import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./pages/root/root.module').then((m) => m.RootModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then((m) => m.ResetPasswordModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersModule)
  },
  {
    path: 'order-products',
    loadChildren: () => import('./pages/order-products/order-products.module').then((m) => m.OrderProductsModule)
  },
  {
    path: 'invoices',
    loadChildren: () => import('./pages/invoices/invoices.module').then((m) => m.InvoicesModule)
  },
  {
    path: 'order-invoices',
    loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersModule)
  },
  {
    path: 'bulk-orders',
    loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersModule)
  },
  {
    path: 'needs-note-review',
    loadChildren: () =>
      import('./pages/needs-note-review/needs-note-review.module').then((m) => m.NeedsNoteReviewModule)
  },
  {
    path: 'customer-service',
    loadChildren: () => import('./pages/customer-service/customer-service.module').then((m) => m.CustomerServiceModule)
  },
  {
    path: 'email-for-picture',
    loadChildren: () =>
      import('./pages/email-for-picture/email-for-picture.module').then((m) => m.EmailForPictureModule)
  },
  {
    path: 'email-for-subject',
    loadChildren: () =>
      import('./pages/email-for-subject/email-for-subject.module').then((m) => m.EmailForSubjectModule)
  },
  {
    path: 'email-for-address',
    loadChildren: () =>
      import('./pages/email-for-address/email-for-address.module').then((m) => m.EmailForAddressModule)
  },
  {
    path: 'photo-upload-errors',
    loadChildren: () =>
      import('./pages/photo-upload-errors/photo-upload-errors.module').then((m) => m.PhotoUploadErrorsModule)
  },
  {
    path: 'invoice-requests',
    loadChildren: () => import('./pages/invoice-requests/invoice-requests.module').then((m) => m.InvoiceRequestsModule)
  },
  {
    path: 'refund-requests',
    loadChildren: () => import('./pages/refund-requests/refund-requests.module').then((m) => m.RefundRequestsModule)
  },
  {
    path: 'special-treatment-order-products',
    loadChildren: () =>
      import('./pages/special-treatment-overview/special-treatment-overview.module').then(
        (m) => m.SpecialTreatmentOverviewModule
      )
  },
  {
    path: 'stuck-in-design',
    loadChildren: () => import('./pages/stuck-in-design/stuck-in-design.module').then((m) => m.StuckInDesignModule)
  },
  {
    path: 'designs',
    loadChildren: () => import('./pages/designs/designs.module').then((m) => m.DesignsModule)
  },
  {
    path: 'special-treatment-designs',
    loadChildren: () =>
      import('./pages/special-treatment-designs/special-treatment-designs.module').then(
        (m) => m.SpecialTreatmentDesignsModule
      )
  },
  {
    path: 'review-designs',
    loadChildren: () => import('./pages/review-designs/review-designs.module').then((m) => m.ReviewDesignsModule)
  },
  {
    path: 'outhouse-reviewer',
    loadChildren: () =>
      import('./pages/outhouse-reviewer/outhouse-reviewer.module').then((m) => m.OuthouseReviewerModule)
  },
  {
    path: 'mid-tier-designer',
    loadChildren: () =>
      import('./pages/mid-tier-designer/mid-tier-designer.module').then((m) => m.MidTierDesignerModule)
  },
  {
    path: 'booth-designer',
    loadChildren: () => import('./pages/booth-designer/booth-designer.module').then((m) => m.BoothDesignerModule)
  },
  {
    path: 'cropper',
    loadChildren: () => import('./pages/cropper/cropper.module').then((m) => m.CropperModule)
  },
  {
    path: 'labeler',
    loadChildren: () => import('./pages/labeler/labeler.module').then((m) => m.LabelerModule)
  },
  {
    path: 'outhouse-designer',
    loadChildren: () =>
      import('./pages/outhouse-designer/outhouse-designer.module').then((m) => m.OuthouseDesignerModule)
  },
  {
    path: 'sticker-designer',
    loadChildren: () => import('./pages/sticker-designer/sticker-designer.module').then((m) => m.StickerDesignerModule)
  },
  {
    path: 'print',
    loadChildren: () => import('./pages/print/print.module').then((m) => m.PrintModule)
  },
  {
    path: 'pre-designed-print',
    loadChildren: () =>
      import('./pages/pre-designed-print/pre-designed-print.module').then((m) => m.PreDesignedPrintModule)
  },
  {
    path: 'special-treatment-prints',
    loadChildren: () =>
      import('./pages/special-treatment-prints/special-treatment-prints.module').then(
        (m) => m.SpecialTreatmentPrintsModule
      )
  },
  {
    path: 'sample-prints',
    loadChildren: () => import('./pages/sample-prints/sample-prints.module').then((m) => m.SamplePrintsModule)
  },
  {
    path: 'bulk/packaging-prints',
    loadChildren: () =>
      import('./pages/bulk/packaging-prints/bulk-packaging-prints.module').then((m) => m.PackagingPrintsModule)
  },
  {
    path: 'print-upload-log',
    loadChildren: () => import('./pages/print-upload-log/print-upload-log.module').then((m) => m.PrintUploadLogModule)
  },
  {
    path: 'print-download-log',
    loadChildren: () =>
      import('./pages/print-download-log/print-download-log.module').then((m) => m.PrintDownloadLogModule)
  },
  {
    path: 'revert-production',
    loadChildren: () =>
      import('./pages/revert-production/revert-production.module').then((m) => m.RevertProductionModule)
  },
  {
    path: 'sorting',
    loadChildren: () => import('./pages/sorting/sorting.module').then((m) => m.SortingModule)
  },
  {
    path: 'shipments',
    loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersModule)
  },
  {
    path: 'shipping',
    loadChildren: () => import('./pages/shipping/shipping.module').then((m) => m.ShippingModule)
  },
  {
    path: 'shipment-on-hold',
    loadChildren: () => import('./pages/shipment-on-hold/shipment-on-hold.module').then((m) => m.ShipmentOnHoldModule)
  },
  {
    path: 'stuck-in-shipping',
    loadChildren: () =>
      import('./pages/stuck-in-shipping/stuck-in-shipping.module').then((m) => m.StuckInShippingModule)
  },
  {
    path: 'status-overview',
    loadChildren: () => import('./pages/status-overview/status-overview.module').then((m) => m.StatusOverviewModule)
  },
  {
    path: 'export',
    loadChildren: () => import('./pages/export/export.module').then((m) => m.ExportModule)
  },
  {
    path: 'manifests',
    loadChildren: () => import('./pages/manifests/manifests.module').then((m) => m.ManifestsModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then((m) => m.UsersModule)
  },
  {
    path: 'user-statistics',
    loadChildren: () => import('./pages/user-statistics/user-statistics.module').then((m) => m.UserStatisticsModule)
  },
  {
    path: 'teams',
    loadChildren: () => import('./pages/teams/teams.module').then((m) => m.TeamsModule)
  },
  {
    path: 'my-team',
    loadChildren: () => import('./pages/my-team/my-team.module').then((m) => m.MyTeamModule)
  },
  {
    path: 'auth-log',
    loadChildren: () => import('./pages/auth-log/auth-log.module').then((m) => m.AuthLogModule)
  },
  {
    path: 'email-log',
    loadChildren: () => import('./pages/email-log/email-log.module').then((m) => m.EmailLogModule)
  },
  {
    path: 'queues',
    loadChildren: () => import('./pages/queues/queues.module').then((m) => m.QueuesModule)
  },
  {
    path: 'api-keys',
    loadChildren: () => import('./pages/api-keys/api-keys.module').then((m) => m.ApiKeysModule)
  },
  {
    path: 'add-settings',
    loadChildren: () => import('./pages/add-settings/add-settings.module').then((m) => m.AddSettingsModule)
  },
  {
    path: 'templates',
    loadChildren: () => import('./pages/templates/templates.module').then((m) => m.TemplatesModule)
  },
  {
    path: 'sock-builder',
    loadChildren: () => import('./pages/sock-builder/sock-builder.module').then((m) => m.SockBuilderModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsModule)
  },
  {
    path: 'scatter-settings',
    loadChildren: () => import('./pages/scatter-settings/scatter-settings.module').then((m) => m.ScatterSettingsModule)
  },
  {
    path: 'amazon',
    loadChildren: () => import('./pages/amazon/amazon.module').then((m) => m.AmazonModule)
  },
  {
    path: 'shippingeasy',
    loadChildren: () => import('./pages/shippingeasy/shippingeasy.module').then((m) => m.ShippingeasyModule)
  },
  {
    path: 'shipping-rules',
    loadChildren: () => import('./pages/shipping-rules/shipping-rules.module').then((m) => m.ShippingRulesModule)
  },
  {
    path: 'ship-by',
    loadChildren: () => import('./pages/ship-by/ship-by.module').then((m) => m.ShipByModule)
  },
  {
    path: 'simple-crop-gallery',
    loadChildren: () =>
      import('./pages/simple-crop-gallery/simple-crop-gallery.module').then((m) => m.SimpleCropGalleryModule)
  },
  {
    path: 'expedite',
    loadChildren: () => import('./pages/expedite/expedite.module').then((m) => m.ExpediteModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsModule)
  },
  {
    path: 'bulk/customers',
    loadChildren: () => import('./pages/bulk/customers/bulk-customers.module').then((m) => m.BulkCustomersModule)
  },
  {
    path: 'bulk/customer-followup',
    loadChildren: () => import('./pages/bulk/customers/bulk-customers.module').then((m) => m.BulkCustomersModule)
  },
  {
    path: 'bulk/conceptor-queue',
    loadChildren: () =>
      import('./pages/bulk/conceptor-queue/bulk-conceptor-queue.module').then((m) => m.BulkConceptQueueModule)
  },
  {
    path: 'bulk/design-queue',
    loadChildren: () =>
      import('./pages/bulk/design-queue/bulk-design-queue.module').then((m) => m.BulkDesignQueueModule)
  },
  {
    path: 'bulk/review-queue',
    loadChildren: () =>
      import('./pages/bulk/review-queue/bulk-review-queue.module').then((m) => m.BulkReviewQueueModule)
  },
  {
    path: 'bulk/designs',
    loadChildren: () => import('./pages/bulk/designs/bulk-designs.module').then((m) => m.BulkDesignsModule)
  },
  {
    path: 'bulk/packaging',
    loadChildren: () => import('./pages/bulk/packaging/bulk-packaging.module').then((m) => m.BulkPackagingModule)
  },
  {
    path: 'bulk/new-designs',
    loadChildren: () => import('./pages/bulk/designs/bulk-designs.module').then((m) => m.BulkDesignsModule)
  },
  {
    path: 'bulk/action-required',
    loadChildren: () => import('./pages/bulk/designs/bulk-designs.module').then((m) => m.BulkDesignsModule)
  },
  {
    path: 'bulk/unanswered-chat',
    loadChildren: () => import('./pages/bulk/designs/bulk-designs.module').then((m) => m.BulkDesignsModule)
  },
  {
    path: 'bulk/pricing-rules',
    loadChildren: () => import('./pages/bulk/pricing-rules/pricing-rules.module').then((m) => m.BulkPricingRulesModule)
  },
  {
    path: 'bulk/customer-import',
    loadChildren: () =>
      import('./pages/bulk/customer-import/bulk-customer-import.module').then((m) => m.BulkCustomerImportModule)
  },
  {
    path: 'sublimation-designer',
    loadChildren: () =>
      import('./pages/sublimation-designer/sublimation-designer.module').then((m) => m.SublimationDesignerModule)
  },
  {
    path: 'sublimation-reviewer',
    loadChildren: () =>
      import('./pages/sublimation-reviewer/sublimation-reviewer.module').then((m) => m.SublimationReviewerModule)
  },
  {
    path: 'sticker-reviewer',
    loadChildren: () => import('./pages/sticker-reviewer/sticker-reviewer.module').then((m) => m.StickerReviewerModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pages/root/root.module').then((m) => m.RootModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
